/* eslint-disable no-nested-ternary */
import { EhrRoles } from "@core/constants";
import { useUser } from "@core/services/nocd-api";
import { MemberChartPageTabs } from "@core/types";
import { CheckboxWithLabel } from "@core/ui";
import DescriptionList from "@core/ui/DescriptionList";
import SectionTitle from "@features/clinician-dashboard/components/SectionTitle";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { InfoOutlined, Refresh } from "@mui/icons-material";
import ChecklistIcon from "@mui/icons-material/Checklist";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  BoxProps,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link as MuiLink,
  List,
  ListItemButton,
  ListSubheader,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { formatInTimeZone } from "date-fns-tz";
import { isEmpty } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";

import useBulkOfferSlotsToMembersV2 from "../hooks/useBulkOfferSlotsToMembersV2";
import useMemberFinder from "../hooks/useMemberFinder";
import { Candidate, MemberFinderResponse } from "../types";
import { formatSchedulingPreferences } from "../utils";

const getDisplayName = (candidate: Candidate): string => {
  if (candidate.type === "current_member") {
    return `${candidate.first_name} ${candidate.last_name}`;
  }

  return candidate.user_id.toString();
};

const RequestSentCard = ({
  candidate,
}: {
  candidate: MemberFinderResponse["already_offered"][string][0];
}) => {
  return (
    <Box
      sx={{
        paddingY: "6px",
        paddingX: "12px",
        border: "1px solid #49DE80",
        borderRadius: "8px",
        backgroundColor: "#EFFDF4",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontWeight="bold">{candidate.label}</Typography>

        <Box
          sx={{
            backgroundColor: "white",
            color: "#49DE80",
            fontSize: "12px",
            fontWeight: 700,
            textTransform: "uppercase",
            py: 0.5,
            px: 1,
            borderRadius: "4px",
          }}
        >
          Request sent
        </Box>
      </Stack>
    </Box>
  );
};

interface MemberFinderInnerProps extends Omit<BoxProps, "maxHeight"> {
  clinicianEmail: string;
  view: "clinician" | "debug";
  maxHeight?: number;
  includeSlotsWithNoCandidates?: boolean;
}

export const MemberFinderInner = ({
  clinicianEmail,
  view = "clinician",
  maxHeight = 400,
  includeSlotsWithNoCandidates = false,
  ...boxProps
}: MemberFinderInnerProps) => {
  const [startedSearch, setStartedSearch] = useState(false);

  const [candidateToView, setCandidateToView] = useState<Candidate | null>(
    null
  );

  const [state, setState] = useState<Record<number, string[]>>({});
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedDurationInMins, setSelectedDurationInMins] =
    useState<number>(60);
  const [skipMaxFirstSessionCheck, setSkipMaxFirstSessionCheck] =
    useState(false);
  const [skipMoveUpsAndWaitlists, setSkipMoveUpsAndWaitlists] = useState(false);
  const [skipMembersWithNextSession, setSkipMembersWithNextSession] =
    useState(false);

  const { data, isLoading, error, refetch, isFetching, isIdle } =
    useMemberFinder(
      clinicianEmail,
      useMemo(
        () => ({
          // This is an expensive query, so we want it to be disabled by default.
          // If the clinician wants to use it, they can enable it manually.
          //
          // However, once they've already manually enabled it, we don't
          // want them to have to do it again. We use a piece of state
          // to track if they started the search.
          enabled: startedSearch,
          durationInMins: selectedDurationInMins,
          skipMaxFirstSessionCheck,
          skipMoveUpsAndWaitlists,
          skipMembersWithNextSession,
          includeSlotsWithNoCandidates,
        }),
        [
          selectedDurationInMins,
          skipMaxFirstSessionCheck,
          skipMoveUpsAndWaitlists,
          startedSearch,
          skipMembersWithNextSession,
          includeSlotsWithNoCandidates,
        ]
      )
    );
  const { mutateAsync: offer, isLoading: isOffering } =
    useBulkOfferSlotsToMembersV2();

  const memberListRef = useRef<HTMLDivElement>(null);
  const numSelectedSlots = Object.values(state).flat().filter(Boolean).length;

  const selectAllCandidatesForSlot = (slot: string) => {
    setState((prev) => ({
      ...prev,
      ...(data?.slots?.[slot]?.candidates ?? [])
        .filter((candidate) => {
          if (candidate.type !== "current_member") {
            return false;
          }

          return true;
        })
        .reduce(
          (acc, candidate) => ({
            ...acc,
            [candidate.user_id]:
              (prev[candidate.user_id] ?? []).includes(slot) ||
              (prev[candidate.user_id] ?? []).length >= 3
                ? prev[candidate.user_id]
                : [...(prev[candidate.user_id] ?? []), slot],
          }),
          {}
        ),
    }));
  };

  const selectSlotsForAllCandidates = () => {
    const newState = Object.entries(data?.slots ?? []).reduce(
      (memo, [slotTime, { candidates }]) => {
        const temp = { ...memo };

        candidates
          .filter((candidate) => {
            if (candidate.type !== "current_member") {
              return false;
            }

            return true;
          })
          .forEach((candidate) => {
            const userId = candidate.user_id;

            if (isEmpty(temp[userId])) {
              temp[userId] = [slotTime];
            }
          });

        return temp;
      },
      {} as Record<number, string[]>
    );

    setState(newState);
  };
  const selectCandidate = useCallback((userId: number, newSlot: string) => {
    setState((prev) => {
      const currentSlots = prev?.[userId] ?? [];

      if (currentSlots.includes(newSlot)) {
        return {
          ...prev,
          [userId]: currentSlots.filter((s) => s !== newSlot),
        };
      }

      if (currentSlots.length >= 3) {
        return {
          ...prev,
          [userId]: currentSlots.slice(0, 2).concat(newSlot),
        };
      }

      return {
        ...prev,
        [userId]: currentSlots.concat(newSlot),
      };
    });
  }, []);

  return (
    <Box
      height={600}
      position="relative"
      sx={{
        paddingY: "24px",
        paddingX: "12px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        height: "100%",
        backgroundColor: "white",
        ...(boxProps?.sx ?? {}),
      }}
      {...(boxProps ?? {})}
    >
      <Box mb={2} display="flex" gap={2}>
        <Stack direction="row" spacing={1} alignItems="center" flex="0 1 250px">
          <CalendarDaysIcon width={32} height={32} />

          <Stack direction="row" alignItems="center">
            <SectionTitle title="Member finder" />

            <IconButton
              size="small"
              color="default"
              href="https://docs.google.com/document/d/1HMl0sIo2ykjzzQx38imwHvxBKUNygUjCv6w8Om9U3Vc"
              target="_blank"
            >
              <InfoIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {view === "debug" ? (
              <CheckboxWithLabel
                name="skip_first_session_check"
                label="Skip max FS check"
                checked={skipMaxFirstSessionCheck}
                onChange={(e) => {
                  setSkipMaxFirstSessionCheck(e.target.checked);

                  // Reset state
                  setState({});
                  setCandidateToView(null);
                  setSelectedDate(null);
                }}
              />
            ) : null}

            {view === "debug" ? (
              <CheckboxWithLabel
                name="skip_move_ups_and_waitlists"
                label="Skip move-ups/waitlists"
                checked={skipMoveUpsAndWaitlists}
                onChange={(e) => {
                  setSkipMoveUpsAndWaitlists(e.target.checked);

                  // Reset state
                  setState({});
                  setCandidateToView(null);
                  setSelectedDate(null);
                }}
              />
            ) : null}

            <CheckboxWithLabel
              name="skip_members_with_next_session"
              label="No next session"
              checked={skipMembersWithNextSession}
              onChange={(e) => {
                setSkipMembersWithNextSession(e.target.checked);

                // Reset state
                setState({});
                setCandidateToView(null);
                setSelectedDate(null);
              }}
            />

            <ToggleButtonGroup
              size="small"
              color="primary"
              value={String(selectedDurationInMins)}
              exclusive
              onChange={(_e, newValue) => {
                setSelectedDurationInMins(Number(newValue));

                // Reset state
                setState({});
                setCandidateToView(null);
                setSelectedDate(null);
              }}
              aria-label="Duration in mins"
            >
              <ToggleButton value="60">60 min</ToggleButton>
              <ToggleButton value="30">30 min</ToggleButton>
            </ToggleButtonGroup>

            <IconButton
              color="primary"
              onClick={() =>
                toast.promise(
                  refetch().then(() => {
                    // Reset state
                    setState({});
                    setCandidateToView(null);
                    setSelectedDate(null);
                  }),
                  {
                    loading: "Refreshing slots and candidates...",
                    success: "Refreshed!",
                    error: (err: Error) =>
                      `Failed to refresh: ${err?.message ?? "Unknown error"}`,
                  }
                )
              }
              disabled={isFetching}
            >
              <Refresh />
            </IconButton>
          </Stack>

          <LoadingButton
            variant="contained"
            startIcon={<SendIcon />}
            disabled={numSelectedSlots === 0 || isOffering}
            loading={isOffering}
            onClick={() =>
              toast.promise(
                offer({
                  clinician_email: clinicianEmail,
                  candidates: Object.entries(state)
                    .map(([candidateUserId, candidateSlots]) => {
                      if (isEmpty(candidateSlots)) {
                        return null;
                      }

                      let type: string | null = null;

                      const matchingSlots = candidateSlots
                        .map((slot) => {
                          const match = (
                            data?.slots?.[slot]?.candidates ?? []
                          ).find((c) => c.user_id === +candidateUserId);

                          if (!match) {
                            return null;
                          }

                          type = match.type;

                          return slot;
                        })
                        .filter(Boolean);

                      if (isEmpty(matchingSlots)) {
                        return null;
                      }

                      if (!type) {
                        return null;
                      }

                      return {
                        user_id: +candidateUserId,
                        type,
                        slots: matchingSlots,
                        duration_in_mins: selectedDurationInMins,
                      };
                    })
                    .filter(Boolean),
                }).then(() => {
                  // Reset state
                  setState({});
                  setCandidateToView(null);
                  setSelectedDate(null);
                }),
                {
                  loading:
                    "Sending requests and refreshing the list of available slots...",
                  success: "Requests sent!",
                  error: (err: Error) => err?.message ?? "Unknown error",
                }
              )
            }
          >
            Send {numSelectedSlots > 0 ? `${numSelectedSlots} ` : ""}offers
          </LoadingButton>
        </Box>
      </Box>

      <Divider sx={{ mb: 2, borderColor: "#CBD5E1" }} />

      {isLoading ? (
        <Box
          height={maxHeight}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          height={maxHeight}
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={3}
        >
          <Typography color="error">{error?.message}</Typography>
        </Box>
      ) : // This will be the initial state.
      isIdle ? (
        <Box
          height={maxHeight}
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={3}
        >
          <Button
            disabled={isFetching}
            variant="outlined"
            onClick={() => {
              setStartedSearch(true);
              return refetch();
            }}
            startIcon={<SearchIcon />}
          >
            Search for members
          </Button>
        </Box>
      ) : isEmpty(data.slots) ? (
        <Box display="flex" justifyContent="center" alignItems="center" p={3}>
          <Typography>Nothing to show yet</Typography>
        </Box>
      ) : (
        <Box display="flex" gap={2}>
          <Box flex="0 1 250px" borderRight="1px solid #CBD5E1">
            <List
              sx={{
                maxHeight,
                overflow: "auto",
              }}
              subheader={
                <ListSubheader
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: "#AAAEB5",
                    lineHeight: 1.5,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                  disableSticky
                >
                  Times
                  <Button
                    size="small"
                    startIcon={<ChecklistIcon />}
                    onClick={() => {
                      selectSlotsForAllCandidates();
                    }}
                  >
                    Select all
                  </Button>
                </ListSubheader>
              }
            >
              {Object.keys(data.slots).map((date) => (
                <ListItemButton
                  selected={selectedDate === date}
                  key={date}
                  onClick={() => {
                    setSelectedDate(date);
                    memberListRef?.current?.scrollTo({
                      top: 0,
                    });
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box>
                      <Typography
                        fontSize="14px"
                        display="block"
                        sx={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          color: "#AAAEB5",
                        }}
                      >
                        {formatInTimeZone(
                          new Date(date),
                          data.clinician_timezone,
                          "EEEE, MMM d"
                        )}
                      </Typography>

                      <Typography display="block">
                        {formatInTimeZone(
                          new Date(date),
                          data.clinician_timezone,
                          "h:mm aa"
                        )}{" "}
                      </Typography>
                    </Box>

                    {Object.values(state).filter((s) => s.includes(date))
                      .length > 0 && (
                      <Chip
                        size="small"
                        color="primary"
                        label={
                          Object.values(state).filter((s) => s.includes(date))
                            .length
                        }
                      />
                    )}
                  </Box>
                </ListItemButton>
              ))}
            </List>
          </Box>

          <Box flex={1}>
            <Stack
              ref={memberListRef}
              spacing={1}
              maxHeight={maxHeight}
              overflow="auto"
            >
              <Box display="flex" alignItems="center" gap={2}>
                <Typography
                  fontSize="14px"
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: "#AAAEB5",
                  }}
                >
                  Members
                </Typography>

                <Button
                  size="small"
                  disabled={isEmpty(data?.slots?.[selectedDate]?.candidates)}
                  onClick={() => {
                    selectAllCandidatesForSlot(selectedDate);
                  }}
                  startIcon={<ChecklistIcon />}
                >
                  Select all
                </Button>
              </Box>

              {Array.isArray(data?.already_offered?.[selectedDate]) &&
              data?.already_offered?.[selectedDate]?.length > 0
                ? data?.already_offered?.[selectedDate].map((candidate) => (
                    <RequestSentCard
                      key={`${candidate.first_name}-${candidate.last_name}-${candidate.slot}-${candidate.created_at}`}
                      candidate={candidate}
                    />
                  ))
                : null}

              {!selectedDate ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <Typography>Choose a slot to see candidates</Typography>
                </Box>
              ) : isEmpty(data?.slots?.[selectedDate]?.candidates) ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <Typography>No candidates to show yet</Typography>
                </Box>
              ) : (
                data.slots[selectedDate].candidates.map((candidate) => (
                  <Box
                    onClick={() => {
                      selectCandidate(candidate.user_id, selectedDate);
                    }}
                    key={`${candidate.user_id}-${candidate.type}`}
                    sx={{
                      position: "relative",
                      paddingY: "24px",
                      paddingX: "12px",
                      borderRadius: "8px",
                      cursor: "pointer",

                      ...(!isEmpty(state[candidate.user_id]) &&
                      state[candidate.user_id].includes(selectedDate)
                        ? {
                            border: "1px solid #6E76EE",
                            backgroundColor: "#F2F8FF",
                          }
                        : { border: "1px solid #CBD5E1" }),
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box width={200}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography
                            fontSize="14px"
                            sx={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              color: "#AAAEB5",
                            }}
                            noWrap
                          >
                            {candidate.label}
                          </Typography>

                          {candidate.type !== "current_member" ? (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                setCandidateToView(candidate);
                              }}
                            >
                              <InfoOutlined fontSize="inherit" />
                            </IconButton>
                          ) : null}
                        </Stack>

                        <Typography>{getDisplayName(candidate)}</Typography>

                        {candidate.type === "current_member" ? (
                          <MuiLink
                            component="button"
                            fontSize={14}
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(
                                `/members/${candidate.user_id}?tab=${MemberChartPageTabs.SESSIONS}`,
                                "_blank"
                              );
                            }}
                          >
                            {candidate.user_id}
                          </MuiLink>
                        ) : null}
                      </Box>

                      <Box>
                        <Typography
                          fontSize="14px"
                          sx={{
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            color: "#AAAEB5",
                          }}
                        >
                          Last session
                        </Typography>

                        <Typography>
                          {candidate.last_session_date
                            ? `${formatInTimeZone(
                                new Date(candidate.last_session_date),
                                data.clinician_timezone,
                                "MMM d"
                              )}${
                                candidate.last_session_duration_in_mins != null
                                  ? ` (${candidate.last_session_duration_in_mins}m)`
                                  : ""
                              }`
                            : "None"}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          fontSize="14px"
                          sx={{
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            color:
                              candidate.type === "current_member" &&
                              !candidate.next_sessions?.length
                                ? "error.main"
                                : "#AAAEB5",
                          }}
                        >
                          {candidate.type === "current_member"
                            ? `Next sessions (${
                                candidate.scheduled_session_count || 0
                              })`
                            : "Next session"}
                        </Typography>

                        {candidate.type === "current_member" ? (
                          <Typography
                            color={
                              candidate.type === "current_member" &&
                              !candidate.next_sessions?.length
                                ? "error.main"
                                : undefined
                            }
                          >
                            {Array.isArray(candidate.next_sessions) &&
                            candidate.next_sessions.length > 0
                              ? candidate.next_sessions
                                  .slice(0, 3)
                                  .map((session) =>
                                    formatInTimeZone(
                                      new Date(session),
                                      data.clinician_timezone,
                                      "MMM d"
                                    )
                                  )
                                  .join(", ")
                              : "None"}
                          </Typography>
                        ) : (
                          <Typography>
                            {candidate.next_session_date
                              ? formatInTimeZone(
                                  new Date(candidate.next_session_date),
                                  data.clinician_timezone,
                                  "MMM d"
                                )
                              : "None"}
                          </Typography>
                        )}
                      </Box>
                    </Stack>

                    {!isEmpty(state[candidate.user_id]) &&
                    state[candidate.user_id].length >= 3 &&
                    !state[candidate.user_id].includes(selectedDate) ? (
                      <Alert
                        sx={{ mt: 1, p: "3px 12px" }}
                        variant="outlined"
                        severity="warning"
                        action={
                          <Button
                            color="inherit"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              selectCandidate(candidate.user_id, selectedDate);
                            }}
                          >
                            Offer this slot instead
                          </Button>
                        }
                      >
                        This member is already selected for 3 other slots
                      </Alert>
                    ) : null}

                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 16,
                        backgroundColor: "#E2E8F1",
                        color: "#0E1729",
                        fontSize: "12px",
                        fontWeight: 700,
                        textTransform: "uppercase",
                        py: 0.5,
                        px: 1,
                        borderRadius: "0 0 8px 8px",
                      }}
                    >
                      {["Child", "Adolescent"].includes(candidate.protocol_type)
                        ? "C&A"
                        : "Adult"}
                    </Box>
                  </Box>
                ))
              )}
            </Stack>
          </Box>
        </Box>
      )}

      {candidateToView ? (
        <Dialog
          open
          onClose={() => setCandidateToView(null)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Member details</DialogTitle>
          <DialogContent>
            <DescriptionList dense>
              <DescriptionList.Header title={getDisplayName(candidateToView)} />
              <DescriptionList.Item label="Age" value={candidateToView.age} />
              <DescriptionList.Item
                label="Protocol type"
                value={candidateToView.protocol_type}
              />
              <DescriptionList.Item
                label="State"
                value={candidateToView.state}
              />
              <DescriptionList.Item
                label="Country"
                value={candidateToView.country_code}
              />
              <DescriptionList.Item
                label="Insurance"
                value={candidateToView.insurance}
              />
              <DescriptionList.Item
                label="Completed sessions"
                value={candidateToView.completed_session_count}
              />
              <DescriptionList.Item
                label="Last session"
                value={
                  candidateToView.last_session_date
                    ? formatInTimeZone(
                        new Date(candidateToView.last_session_date),
                        data.clinician_timezone,
                        "MMM d, yyyy"
                      )
                    : undefined
                }
              />
              <DescriptionList.Item
                label="Next session"
                value={
                  candidateToView.next_session_date
                    ? formatInTimeZone(
                        new Date(candidateToView.next_session_date),
                        data.clinician_timezone,
                        "MMM d, yyyy"
                      )
                    : undefined
                }
              />
              <DescriptionList.Item
                label="Scheduling pref."
                preserveWhiteSpace
                value={formatSchedulingPreferences(
                  candidateToView.scheduling_preferences,
                  candidateToView.diagnostic_timezone
                )}
              />
              <DescriptionList.Item
                label="Gender pref."
                value={candidateToView.therapist_preference_gender}
              />
            </DescriptionList>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setCandidateToView(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Box>
  );
};

interface MemberFinderProps extends Omit<BoxProps, "maxHeight"> {
  includeSlotsWithNoCandidates?: boolean;
}

export default function MemberFinder({
  includeSlotsWithNoCandidates = false,
  ...boxProps
}: MemberFinderProps) {
  const { data: user } = useUser();

  const isClinician = Boolean(user?.roles?.includes(EhrRoles.CLINICIAN));

  if (!user || !isClinician) {
    return null;
  }

  return (
    <MemberFinderInner
      maxHeight={1000}
      clinicianEmail={user.email}
      includeSlotsWithNoCandidates={includeSlotsWithNoCandidates}
      {...(boxProps ?? {})}
      view="clinician"
    />
  );
}
